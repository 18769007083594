import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

function MortgageChart({mortgageDataToPlot}) {
  function calculate_monthly_payment(amount, yearly_rate, months) {
    return yearly_rate * amount / (1 - ((1 + yearly_rate) ** (-months)));
  }

  function cumulative_interest_paid(amount, monthly_rate, monthly_payment, months_passed) {
    return (amount * monthly_rate - monthly_payment) * (
        (1 + monthly_rate) ** months_passed - 1) / monthly_rate + months_passed * monthly_payment;
  }

  function calculate_monthly_bank_profits(amount, yearly_rate, monthly_payment, total_months) {
    let bank_profit = [
        cumulative_interest_paid(amount, yearly_rate / 12, monthly_payment, 1)]; // the first month
    let monthly_bank_profits = [bank_profit[0]];
    for (let month = 1; month < total_months; month++) {
      bank_profit.push(
          cumulative_interest_paid(amount, yearly_rate / 12, monthly_payment, month+1));
      monthly_bank_profits.push(bank_profit[month] - bank_profit[month - 1]);
    }
    return monthly_bank_profits;
  }

  function calculate_plot_data(amount, yearly_rate_display, years) {
    let yearly_rate = yearly_rate_display / 100;
    let months_in_year = 12;
    let months = years * months_in_year;
    let monthly_payment = 0;
    let total_bank_profit = 0;
    let monthly_bank_profits = [];
    try {
      monthly_payment = calculate_monthly_payment(
          amount,
          yearly_rate / months_in_year,
          years * months_in_year);
      total_bank_profit = cumulative_interest_paid(
          amount,
          yearly_rate / months_in_year,
          monthly_payment,
          months);
      monthly_bank_profits = calculate_monthly_bank_profits(
          amount,
          yearly_rate,
          monthly_payment,
          months);
    } catch(err) {
      alert("Error: " + err);
    }
    let total_payment = monthly_payment * years * months_in_year;
    let monthly_repayments = [];
    for (let i = 0; i < monthly_bank_profits.length; i++) {
      monthly_repayments.push(monthly_payment - monthly_bank_profits[i]);
    }
    let months_labels = [];
    for (let month = 1; month <= months; month++) {
      months_labels.push(month.toString());
    }
    return {
      "amount": amount,
      "yearly_rate": yearly_rate_display,
      "years": years,
      "monthly_payment": monthly_payment,
      "total_payment": total_payment,
      "total_bank_profit": total_bank_profit,
      "monthly_bank_profits": monthly_bank_profits,
      "monthly_repayments": monthly_repayments,
      "months_labels": months_labels
    };
  }

  var plotData = calculate_plot_data(
      mortgageDataToPlot["amount"],
      mortgageDataToPlot["yearly_rate"],
      mortgageDataToPlot["years"]);

  const chartData = {
    labels: plotData["months_labels"],
    datasets: [
      {
        label: "Bank's monthly profits",
        data: plotData["monthly_bank_profits"],
        backgroundColor: "rgba(255, 204, 0, 0.9)",
        borderColor: "rgba(255, 204, 0, 0.9)"
      },
      {
        label: "Monthly repayments",
        data: plotData["monthly_repayments"],
        backgroundColor: "rgba(51, 153, 51, 0.9)",
        borderColor: "rgba(51, 153, 51, 0.9)"
      }
    ]
  };

  const options = {
    aspectRatio: 1.75,
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Months"
        },
        stacked: true
      },
      y: {
        title: {
          display: true,
          text: "Total monthly payment"
        },
        stacked: true
      }
    }
  };

  return (
    <div>
      < Bar data={chartData} options={options} />
    </div>
  );
};

export default MortgageChart;
