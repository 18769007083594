import { useState } from 'react';
import './BasicMortgageParams.css';

function BasicMortgageParamsForm({setMortgageData}) {
  const [name, setName] = useState("");

  const handleSubmit  = (event) => {
    let mortgageData = {
      "amount": event.target.amount.value,
      "yearly_rate": event.target.yearly_rate.value,
      "years": event.target.years.value
    }
    setMortgageData(mortgageData);
    event.preventDefault();
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h3>We can calculate the distribution of your mortgage payments over the years.</h3>
        <h4>Please, enter parameters of your mortgage.</h4>
        <p>Mortgage amount:</p>
        <p><input required placeholder="Input mortgage amount" type = "number" min="1" name = "amount"/></p>
        <p>Yearly rate (%):</p>
        <p><input required min="0.01" step="0.01" placeholder="Input yearly rate" type = "number" name = "yearly_rate"/></p>
        <p>Number of years:</p>
        <p><input required placeholder="Input number of years" type = "number" min="1" name = "years" /></p>
        <p><button className="button">Calculate</button></p>
      </form>
    </div>
  )
}

export default BasicMortgageParamsForm;
