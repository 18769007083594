import logo from './logo.svg';
import './App.css';
import './index.css';
import { useState } from 'react';
import BasicMortgageParamsForm from './BasicMortgageParams';
import MortgageChart from './MortgageChart';

function App() {
  const [mortgageData, setData] = useState('');
  
  const setMortgageData = (data) => {
    setData(data);
  }

  return (
    <div className="div-main">
      <div className="grid-container">
        <div className="item_header">
          <h1>Mortgage Planning</h1>

        </div>
        <div className="item_data">
          <BasicMortgageParamsForm setMortgageData={setMortgageData}/>
        </div>
        <div className="item_chart">
          <MortgageChart mortgageDataToPlot={mortgageData}/>
        </div>
      </div>
    </div>
  );
}

export default App;
